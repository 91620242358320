<template>
  <div
    class="wrapper"
    :style="{
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }"
    @click="goTo"
  >
    <div class="main">
      <h1>{{ title }}</h1>
      <p class="bottom">{{ coins }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashEarnItem",
  props: {
    title: String,
    coins: String,
    backgroundImage: String,
    slug: String,
  },
  methods: {
    goTo() {
      this.$router.push(this.slug);
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 350px;
  width: 250px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
  margin: 7.5px;
}
.main {
  padding: 10px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  text-align: center;
  position: relative;
}
.bottom {
  bottom: 5px;
  position: absolute;
  width: calc(100% - 20px);
}
h1 {
  font-size: 25px;
}
</style>
