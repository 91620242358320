<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <div
          :style="{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-around',
          }"
        >
          <DashEarnItem
            v-for="offer in offers"
            :key="offer.slug"
            :title="offer.title"
            :coins="offer.coins"
            :backgroundImage="offer.img"
            :slug="offer.slug"
            :style="{
              marginBottom: '25px',
            }"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DashEarnItem from "@/components/DashEarnItem.vue";

export default {
  components: {
    DashEarnItem,
  },
  data() {
    return {
      offers: [],
    };
  },
  asyncComputed: {
    async offers() {
      const response = await axios({
        url: "/offers",
      });
      return response.data.offers;
    },
  },
};
</script>

<style>
</style>